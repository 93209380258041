<template>

  <div class="page center">
    <div class="container mx-auto payment">
      <div class="payment-page">
        <h1 style="margin-top: 40px;color: hsla(0, 0%, 100%, .8)" class="text-center page-title">
          {{ this.$route.query.title }}</h1>
        <div class="grid  grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4"
             v-if="amountList.length > 0"
        >
          <router-link v-for="(item, index) in amountList" :key="index" :to="{
                      path: '/choose/bank/payment',
                      query:  {id:item.id, amount: item.price }
                    }"
                       class="payment-card-list">
            <div class="amount-card-section">
              <h2 class="price"><span>{{ formattedPrice(item.price) }}₮</span></h2>
              <span class="duration">{{ item.title }}</span>
              <a href="/choose/bank/payment" class="choose-btn"><span style="color: white">Эрх авах</span></a></div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style>

.payment-card-list {
  background: #121212;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px 40px;
}

.payment-card-list:hover {
  border: 1px solid #E67E22;
}

.price {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsla(0, 0%, 100%, .8);
}

.duration {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsla(0, 0%, 100%, .8);
}

.amount-card-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.choose-btn {
  background: #E67E22;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  transition: all .25s !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 180px;
  margin-top: 20px;
}
</style>
<script>

import api from "@store/_boot/baseApi";

export default {
  data() {
    return {
      amountList: [],
      loadingAmount: false,
    }
  },
  mounted() {
    console.log(this.$route.query.title);
  },
  created() {
    this.getPaymentAmount();
  },
  methods: {
    formattedPrice(price) {
      if (typeof price === "number") {
        return new Intl.NumberFormat('mn-MN', {
          minimumFractionDigits: 0
        }).format(price);
      }
      return price;
    },
    getPaymentAmount() {
      this.loadingAmount = true;
      api.get('api/m/payment/options/list')
          .then((data) => {
            this.amountList = data.data;
            this.loadingAmount = false;
          })
          .catch((error) => {
            this.loadingAmount = false;
            console.error("Error fetching data:", error);
          });
    },
  }
}
</script>
